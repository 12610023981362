// I don't really think this is neccessry for this size site. Leaving, but not using.
const pages = [
  'Portfolio', 'Services', 'Contact', 'Something'
]
const Variables = {
  //colors
  black: "#2d3142ff",
  lightGrey: "#bfc0c0ff",
  white: "#ffffffff",
  orange: "#ef8354ff",
  darkGrey: "#4f5d75ff",
  submit: "#0000FF",
  //LOGOS
  logo: "logo.png",
  landingImage: "roof1.jpg",
  pages: pages,
  // images
  contactRoof: "roof-contact@3x.png", 

  //image text
}
export default Variables
